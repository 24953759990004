$button-color: rgb(2, 217, 163);
$link-color: rgb(255, 0, 119);


body{
  font-family:'Roboto', Helvetica, sans-serif;
  
}

h1, h2, h3, h4{
  font-family: 'Montserrat';
}
h1{
  // font-size: 4em;
  font-weight: 400;

}

h2{
  font-weight: 300;
}

h3{
  font-size: 1.25em;
  font-weight: 400;
}

a{
  color: $link-color;
  text-decoration: none;
}

button{
  border:none;
  background-color: $button-color;
  padding: 8px 16px;
  border-radius: 1px;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  color:white;
  cursor: pointer;
}

form{
  
  display: flex;
  flex-direction: column;
  gap:8px;

  input{
    // width:100%;
    padding: 8px 12px;
  }
}





