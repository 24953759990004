.header{
  position: fixed;
  top:0;
  left:150px;
  background-color: white;
  width: calc(100% - 48px - 150px);
  display: flex;
  justify-content: flex-end;
  padding: 0 24px;
  // border-bottom: 1px solid #ccc;
  

  .navigation-buttons{
    gap:20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

      

    @media only screen and (max-device-width: 600px) {
      justify-content: center;

    }
  }

}