$menu-item-background: rgb(6, 45, 49);
$menu-item-highlight: rgb(2, 237, 178);

.menu-item{
    width:unset;
    padding: 24px 24px;
    
    font-size: .9em;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    color: rgb(157, 157, 157);
    border-bottom: 1px solid #333;
    border-top: 1px solid #333;
    // background-color: rgb(36, 35, 37);
  

  &.current{
    width:unset;
    color: $menu-item-highlight;
    background-color: $menu-item-background;
    
    // border-bottom: 2px solid rgb(0, 177, 133);
    // border-top: 2px solid rgb(0, 177, 133);
  }

  &.alternate{
    color: rgb(255, 111, 161);
    background-color: rgb(65, 9, 9);
    padding: 12px;
  }
}