.card{
  
  width: 180px;
  min-height: 80px;
  

  &.link{
    cursor: pointer;
  }

  img{
    width:100%;
    margin:auto;
  }

  .card-content{
    width: 90%;
    padding: 0 5%;
  }

  h4{
    overflow: hidden;
  }
  
}

.static-buttons {
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 16px;
  bottom: 0;
  right: 0;
}
.card-layout{
  position: relative;
  border: 1px solid #ccc;
}

