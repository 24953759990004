.panel{
  width: 240px;
  height: 320px;
  margin:auto;
  padding: 36px 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap:30px;
  background-color: white;
  border: 1px solid #ccc;
}