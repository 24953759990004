.navigation{
  position:fixed;
  left:0;
  top:0;
  height:100%;
  width: 150px;
  background-color: rgb(40, 27, 56);
  padding-top:2px;
  h4{
    padding: 8px 16px;
  }



  .navigation-footer{
    // padding:16px;
    position:absolute;
    bottom:0;
    width:100%;

    color:white;
    // font-size: 0.65em;
    font-weight: 600;
    overflow: hidden;

    .profile{
      padding: 16px;
    }
  }
}
