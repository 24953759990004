.confirmation-panel{
  gap:10px;
  display:flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  // justify-content: baseline;
  // align-items: center;
  background-color: white;
  padding:10px;
  border:1px solid #ccc;

  position:absolute;
  bottom:0;
  right:0;
  p{
    font-size: 14px;
    font-weight: 600;
    margin:0;
    padding:0;

  }
}