.section{
 
  // background-color: blue;

  background-size: cover;
  background-color: white;
  padding: 20px;
 
  
  &.center{
    width: 60%;
    margin:auto;
  }

  img{
    max-height: 500px;
  }
 

  &.splash{
    
  }

}



